import React from "react";

import {
  StackedCarousel,
  ResponsiveContainer,
} from "react-stacked-center-carousel";

import ss from "../../img/hero.mp4";
import reel_mockup from "../../img/reel-mock.png";
import insta_logo from "../../img/insta.png";

import ios_back from "../../img/ios-back.png";
import ios_front from "../../img/ios.png";
import ios_notch from "../../img/ios-notch.png";

import video from "../../img/recording.mp4";

import video_1 from "../../img/reel/5.mp4";
import video_2 from "../../img/reel/2.mp4";
import video_3 from "../../img/reel/3.mp4";
import video_4 from "../../img/reel/4.mp4";

const carousel_data = [
  {
    cover: video_1,
    title: "Inception",
  },
  {
    cover: video_2,
    title: "Inception",
  },
  {
    cover: video_3,
    title: "Blade Runner 2049",
  },
  {
    cover: ss,
    title: "Venom",
  },
];

const InstagramWidget = () => {
  const ref = React.useRef();

  var [show, setShow] = React.useState(false);

  React.useEffect(() => {
    if (window.innerWidth <= 727) {
      setShow(false);
    } else {
      setShow(true);
    }
  }, []);

  return (
    <section className="instagram-section" id="sectionOne">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 mobile-view-wrap">
            {show ? (
              <>
                <a
                  className="reel-mock"
                  href="https://instagram.com"
                  target="_blank"
                >
                  <img src={reel_mockup} alt="reel-mock" />
                </a>
                <ResponsiveContainer
                  carouselRef={ref}
                  render={(parentWidth, carouselRef) => {
                    let currentVisibleSlide = 5;
                    if (parentWidth <= 1440) currentVisibleSlide = 3;
                    else if (parentWidth <= 1080) currentVisibleSlide = 1;
                    return (
                      <StackedCarousel
                        ref={carouselRef}
                        data={carousel_data}
                        carouselWidth={parentWidth}
                        slideWidth={320}
                        slideComponent={Slide}
                        maxVisibleSlide={3}
                        currentVisibleSlide={currentVisibleSlide}
                        useGrabCursor={true}
                        fadeDistance={0.6}
                      />
                    );
                  }}
                />
              </>
            ) : (
              <div className="ios-mobile">
                <div className="ios-mobile-img-back">
                  <img src={ios_back} alt="ios_back"/>
                </div>
                <div className="ios-mobile-img">
                  <img src={ios_front} alt="ios_front"/>  
                </div>
                {/* <div className="ios-mobile-img-notch">
                  <img src={ios_notch} alt="ios_notch" />
                </div> */}
                <div className="ios-mobile-content">
                  <video autoPlay="autoPlay" muted="muted" loop="loop">
                    <source src={video} type="video/mp4" />
                  </video>
                </div>
              </div>
            )}
          </div>
          <div
            className="col-lg-6 col-md-6 col-sm-12 col-xs-12 follow-wrapper"
            id="textOne"
          >
            <a
              href="https://instagram.com/charaiveti.chalteraho?igshid=MzRlODBiNWFlZA=="
              target="_blank"
              className="insta-logo"
            >
              <img src={insta_logo} alt="instagram" />
            </a>
            <h2 className="title">Follow Charaiveti on Instagram!</h2>
            <p className="desc">
              We are a team of travel experts who are passionate about creating
              unforgettable experiences for our customers. Follow us on
              Instagram to get inspired for your next adventure!
            </p>
            <a
              className="btn-ig"
              href="https://instagram.com/charaiveti.chalteraho?igshid=MzRlODBiNWFlZA=="
              target="_blank"
            >
              Follow
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default InstagramWidget;

const Slide = React.memo(
  function (props) {
    const { data, dataIndex } = props;
    const { cover } = data[dataIndex];

    return (
      <div style={{ width: "100%", aspectRatio: "9/19.5" }}>
        <video
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            borderRadius: 44,
          }}
          draggable={false}
          autoPlay
          muted
          loop
        >
          <source src={cover} type="video/mp4" />
        </video>
      </div>
    );
  },
  function (prev, next) {
    return prev.dataIndex === next.dataIndex;
  }
);
