import { Splide, SplideSlide } from "@splidejs/react-splide";
import React from "react";

import five_star_clr from "../../img/ico/five-star-clr.svg";

const reviews = [
  {
    name: "Nitin M.",
    reviews:
      "The group tour to Himachal Pradesh was a blast! Made some incredible memories with fellow travelers, and the agency's planning was spot-on.",
  },
  {
    name: "Awni B.",
    reviews:
      "I wanted a unique adventure, and Charaiveti delivered. The customized solo tour was filled with incredible experiences and breathtaking scenery.",
  },
  {
    name: "Rajesh S.",
    reviews:
      "The Himachal group tour was an absolute delight. Kudos to the agency for creating a perfect blend of adventure and relaxation.",
  },
  {
    name: "Aditya P.",
    reviews:
      "I was a bit hesitant about group travel, but Charaiveti made it unforgettable. The camaraderie and the destinations were fantastic!",
  },
  {
    name: "Shruti S.",
    reviews:
      "My solo trip to Himachal Pradesh was a dream come true, thanks to Charaiveti. Everything was seamless, and I felt safe and well taken care of.",
  },
  {
    name: "Amit V.",
    reviews:
      "I couldn't have asked for a better solo trip. Charaiveti crafted an itinerary that perfectly matched my interests, and I had an incredible time.",
  },
  {
    name: "Sophie R.",
    reviews:
      "As a solo female traveler, safety was my top concern. Charaiveti exceeded my expectations, and I had the time of my life exploring Himachal Pradesh.",
  },
];

const TestimonialWidget = () => {
  return (
    <section className="testimonial-section">
      <div className="container">
        <div className="testimonial-wrapper">
          <div className="testimonial-header">
            <h2>What our customers say</h2>
            <p>
              Explore the Stories of Adventure and Joy Shared by Our Valued Travelers
            </p>
          </div>
          <div className="testimonial-slider">
            <Splide
              options={{
                rewind: false,
                perPage: 4,
                gap: "0px",
                perMove: 1,
                pagination: false,
                breakpoints: {
                  820: {
                    perPage: 1,
                  },
                },
              }}
            >
              {reviews &&
                reviews.map((review, index) => (
                  <SplideSlide key={index}>
                    <div className="testimonial-card">
                      <div className="testimonial-content">
                        <img src={five_star_clr} alt="five_star_clr" />
                        <p>
                          {review.reviews}
                        </p>
                      </div>
                      <div className="testimonial-author">
                        <div className="author-name">
                          <h3>{review.name}</h3>
                        </div>
                      </div>
                    </div>
                  </SplideSlide>
                ))}
            </Splide>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TestimonialWidget;
